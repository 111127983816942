@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");
@import url('https://fonts.googleapis.com/css2?family=Audiowide&family=Roboto+Flex&display=swap');

.UnivBody {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
}



.popup {
  width: 30%;
  height: auto;
  flex-shrink: 0;
  border-radius: 12px;
  background: rgba(24, 24, 24, 0.40);
  box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(9.5px);
  text-align: center;
  font-family: 'Audiowide', sans-serif;
  color: #fff;
  overflow: hidden;
  z-index: 20000000000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: absolute;
  top:0;
  left:0;
  margin-top: 14%;
  margin-left: 35%;
  padding-bottom: 2%;
}

.popupHeading {
  font-size: 22px;
  font-family: 'Audiowide', sans-serif;
  font-weight: 200;
}

.popupContent {
  margin-top: 15px;
  font-size: 12px;
  font-family: 'Audiowide', sans-serif;
  font-weight: 100;
}

.popupClose {
  position: absolute;
  cursor: pointer;
  color: #FFF;
  font-size: 20px;
  top: 0;
  right: 0;
  margin: 2% 3% 0 0;
}



.popupButton {
  margin-top: 20px;
  padding: 5px 30px;
  background-color: #fff;
  color: #384039;
  font-family: 'Audiowide', sans-serif;
  font-size: 10px;
  font-weight: 400;
  border-radius: 20px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popupButton:hover {
  background-color: #384039;
  color: #fff;
}

.backBtn{
  position: absolute;
  top:5.8%;
  left: 4%;
  font-size: 40px;
  background:none ;
  color: white;
  border: none;
  transition-duration: 100ms;
}

.backBtn:hover{
  top:5.5%;
  left: 4%;
  font-size: 42px;
  text-shadow: 0 0 10px #ffffff;
  transition-duration: 100ms;
}






