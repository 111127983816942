.overmin{
    overflow: hidden;
}

.bgs{
    background-position: center;
    width: 100%;
    height: 100vh;
    transition-duration: 50ms;
    transition-timing-function: ease-in-out;

    overflow-y: hidden;
    overflow-x: hidden;
}

.bgblur{
    scale: 1.1;
    filter: blur(5px);
    overflow-y: hidden;
    overflow-x: hidden;
}


.cmapbg{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)), url("../../../../public/cmap.jpg");
    background-size: cover;
}

.bunkerbg{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 1)), url("./bunker.jpg");
    background-size: cover;
}

.jailbg{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)), url("./jail_png.png");
    background-size: cover;
}

.csbg{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0), rgba(0, 0, 0, 1)), url("./control_png.png");
    background-size: cover;
}

.vaultbg{
    background-image: linear-gradient(to bottom, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 1)), url("./vault_png.png");
    background-size: cover;
}

.wrapper2{
    width: 100%;
    display: flex;
    position: absolute;
    top: 0;
    background-color: transparent;
    overflow-y: hidden;
    overflow-x: hidden;
}

.content{
    width: calc(100% - 100px);
    display: flex;
    flex-direction: column;
    align-items: center;
}

.levels{
    margin-top: 60px;
    display: flex;
    flex-wrap: wrap;
    width: 900px;
}

.levelcont{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    transition-duration: 1s;
}
.level{
    width: 150px;
    height: 450px;

    background: rgba(24, 24, 24, 0.251);
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 2px );

    

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition-duration: .3s;
    box-shadow: 0 5px 10px 10px rgba(0, 0, 0, 0.5);

    border: 2px solid rgb(61, 61, 61);

    cursor: pointer;
}

.level, .level > h5{

    color: grey;
}

.activelvl{
    border: 2px solid rgb(0, 210, 218);
    box-shadow: 0 0px 20px 2px rgba(0, 247, 255, 0.2);
}

.level:hover{
    border: 2px solid rgb(0, 210, 218);
    box-shadow: 0 0px 20px 2px rgba(0, 247, 255, 0.2);
}

.activelvl:hover{
    border-color: rgb(44, 248, 255);
    box-shadow: 0 0px 40px -5px rgba(0, 247, 255, 0.4);
}