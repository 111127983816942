.QsDiv{
    color: white;
    background-color: none;
    font-size: 100px;
    width: 116%;
    border: none;
}

.QsButtons{
    margin: 0%;
    background: none;
    width: 100%;
    border: none;
}





.Watch{
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: Arial, sans-serif;
    font-size: 85px;
    font-weight: bold;
    color: #ffffff;
    background: rgba(42, 252, 0, 0.24);
  box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
  backdrop-filter: blur(9.5px);
    padding: 20px;
    border:rgba(0, 0, 0, 0.432) 8px solid;
    border-radius: 30px;
    position: relative;
    z-index: -1;
    text-align: center;
    min-width: 500px;
    cursor:auto;
  }