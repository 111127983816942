.sidebar{
    width: 100px;
    height: 100vh;

    background: #2e2e2e40;
    backdrop-filter: blur( 3px );
    -webkit-backdrop-filter: blur( 4px );

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transition-duration: .5s;
}

.sidebar:hover{
    background: #44444435;
}

.navitem{
    width: 100%;
    text-align: center;

    cursor: pointer;
    padding: 0px 0px;

    display: flex;
    align-items: center;
    justify-content: center;
    transition-duration: .3s;
    z-index: 200;

    color: rgb(105, 105, 105);
}

.navitem:hover{
    color: white;
}

.navitem::after{
    font-family: audio;
    position: absolute;
    background-color: white;
    color: black;

    height: 40px;
    width: 200px;

    transform: translateX(70%);
    border-radius: 15px;

    display: flex;
    align-items: center;
    justify-content: center;

    transition: opacity ease-in-out 200ms,
                scale ease 200ms 50ms;
    opacity: 0;
    scale: 0;

    z-index: 100;
}

.active{
    color: white;
}

.navitem:hover:after{
    transition: opacity ease-in-out 200ms 50ms,
                scale ease 200ms;
    opacity: 1;
    scale: 1;
}


.rule::after{
    content: 'RULEBOOK';
}

.play::after{
    content: 'PLAY GAME';
}

.leader::after{
    content: 'LEADERBOARD';
}

.signout::after{
    content: 'SIGN OUT';
}
.members::after{
    content: "MEMBERS";
}

