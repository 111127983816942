// .background{
//   position: relative;
//   position: fixed;
//   z-index:-200
// }
// .foreground{
//   position: absolute;
//   top:-30%;
//   left: 0;
//   z-index:-100

// }

// .logo {
//     height: 60px;
//     margin-top: 45px;
//     margin-left: 20px;
// }

// .grad {
//   position: absolute;
//   background: rgb(4, 3, 10);
//   background: linear-gradient(0deg, rgba(4, 3, 10, 1) 8%, rgba(4, 3, 10, 0) 70%);
//   height: 100%;
//   width: 100%;
//   margin-top: 300px;
//   transform: translateZ(0px) scale(1);
// }






.arrow-icon {
  height: 2.8em;
  width: 2.8em;
  display:block;
  padding: 0.5em;
  margin: 1em auto;
  position: relative;
  cursor: pointer;
  border-radius: 4px;
}

.left-bar {
  position: absolute;
  background-color: transparent;
  top: 0;
  left:0;
  width: 40px;
  height: 10px;
  display: block;
  transform: rotate(35deg);
  float: right;
  border-radius: 2px;
  &:after {
    content:"";
    background-color: white;
    width: 40px;
    height: 10px;
    display: block;
    float: right;
    border-radius: 6px 10px 10px 6px;
    z-index: -1;
  }
}

.right-bar {
  position: absolute;
  background-color: transparent;
  top: 0px;
  left:26px;
  width: 40px;
  height: 10px;
  display: block;
  transform: rotate(-35deg);
  float: right;
  border-radius: 2px;
  &:after {
    content:"";
    background-color: white;
    width: 40px;
    height: 10px;
    display: block;
    float: right;
    border-radius: 10px 6px 6px 10px;
    z-index: -1;
  }
}



















.glitch {
  color: white;
  font-size: 8vw;
  position: relative;
  //   width:400px;
  margin: 0 auto;
}

@keyframes noise-anim {
  $steps: 20;

  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
    }
  }
}

.glitch:after {
  content: attr(data-text);
  position: absolute;
  left: 2px;
  text-shadow: -1px 0 red;
  top: 0;
  color: white;
  //   background:rgba(0, 141, 223, 0.307);
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim 2s infinite linear alternate-reverse;
}

@keyframes noise-anim-2 {
  $steps: 20;

  @for $i from 0 through $steps {
    #{percentage($i*(1/$steps))} {
      clip: rect(random(100)+px, 9999px, random(100)+px, 0);
    }
  }
}

.glitch:before {
  content: attr(data-text);
  position: absolute;
  left: -2px;
  text-shadow: 1px 0 blue;
  top: 0;
  color: white;
  //   background:rgba(0, 141, 223, 0.307);
  overflow: hidden;
  clip: rect(0, 900px, 0, 0);
  animation: noise-anim-2 3s infinite linear alternate-reverse;
}



.join,
.create {
  width: 25vw;
  height: 7vh;
  margin-bottom: 20px;

  border-radius: 10px;
  outline: none;
  border: 0px solid;
  align-self: center;

  font-family: macer;
  font-size: 2cqi;
  cursor: pointer;
}


@media only screen and (max-width: 768px) {

  .join,
  .create {
    width: 60vw;
    font-size: 5vw;

  }
}


.navmenu {
  padding: 22px;
}















/* STylish button in cretate team */

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');

@import url('https://fonts.googleapis.com/css?family=Mukta:700');

@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/6.0.0-beta3/css/all.min.css");

$bg: #f3f8fa;
$white: #fff;
$black: #282936;

@mixin transition($property: all, $duration: 0.45s, $ease: cubic-bezier(0.65, 0, .076, 1)) {
  transition: $property $duration $ease;
}

.container{
  margin-left: -35%;
}

.button_container {
  width: 100vw;
}

.learn-more {
  transform: rotate(180deg);
  position: relative;
  display: inline-block;
  cursor: pointer;
  outline: none;
  border: 0;
  vertical-align: middle;
  text-decoration: none;
  background: transparent;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  font-family: macer;

  &.learn-more {
    width: 12rem;
    height: auto;

    .circle {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      position: relative;
      display: block;
      margin: 0;
      width: 3rem;
      height: 3rem;
      background: rgba(255, 255, 255, 0.4);
      box-shadow: 0px 0px 52px 13px rgba(0, 0, 0, 0.50);
      backdrop-filter: blur(9.5px);
      border-radius: 1.625rem;

      .icon {
        @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto;
        background: $white;

        &.arrow {
          @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
          left: 0.625rem;
          width: 1.125rem;
          height: 0.125rem;
          background: none;

          &::before {
            position: absolute;
            content: '';
            top: -0.25rem;
            right: 0.0625rem;
            width: 0.625rem;
            height: 0.625rem;
            border-top: 0.125rem solid #fff;
            border-right: 0.125rem solid #fff;
            transform: rotate(45deg);
          }
        }
      }
    }

    .button-text {
      @include transition(all, 0.45s, cubic-bezier(0.65, 0, .076, 1));
      transform: rotate(180deg);
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      padding: 0.75rem 0;
      margin: 0 0 0 1.85rem;
      color: transparent;
      font-weight: 700;
      line-height: 1.6;
      text-align: center;
      text-transform: uppercase;
      font-family: macer;
    }
  }

  &:hover {
    &.learn-more{
          transition-duration: 900ms;
          margin-left:65%;
      
    }
    .circle {
      width: 200%;
      scale: 1.3;
      .icon {
        &.arrow {
          background: $white;
        }
      }
    }

    .button-text {
      color: $white;
      font-family: audio;
      text-shadow: 0 2px 10px black;
      font-size: 18px;
    }
  }
}





@supports (display: grid) {
  

  #container {
    grid-area: main;
    align-self: center;
    justify-self: center;
  }
}

@media screen and (max-width: 800px) {
  .container {
    float: unset;
    margin-top: 1%;
    align-self: center;
    justify-content: center;
  }
    .glitch {
      font-size: 12vw;
    }

}