.logincont{
    height: 100vh;
    display: flex;

    flex-wrap: wrap;
}
.login{
    position: relative;
    width: 35%;
    height: 100%;
    min-width: 300px;
    flex-grow: 1;
    background-image: radial-gradient(ellipse, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, .7) 100%);
    background-size: 400% 400%;
	animation: gradient 10s ease infinite;
    object-fit: cover;
}

.login::after{
    content: '';
    position: absolute;

    height: 100%;
    width: 100%;
    inset: 0;
    z-index: -2;
    background-image: url("../../../public/cmap.jpg");
}

@keyframes gradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.videocont{
    width: 65%;
    height: 100%;
    flex-grow: 1;
    background-color: black;
    position: relative;
}

.videocont video{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;
}

.overlay{
    width: 100%;
    height: 100%;
    position: absolute;
    object-fit: cover;
    z-index: 0;

    background: radial-gradient(ellipse, rgba(0,0,0, .5) 0%, rgba(0,0,0,1) 80%);
}

.caption{
    width: 100%;
    height: 100%;
    z-index: 1;
    position: relative;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}


.seperator{
    width: 70%;
    border: 2px solid white;
    border-radius: 10px;
}

input, .Submit-btn{

    margin-bottom: 20px;
    padding: 10px 0px;
    border-radius: 10px;

    border: 0px solid;
    outline: 0px solid;
    text-align: center;

    font-family: audio;
    color: white;

    background: rgba(46, 50, 68, 0.25);
    backdrop-filter: blur( 5px );
    -webkit-backdrop-filter: blur( 4px );
    border: 1px solid rgba(255, 255, 255, 0.1);
}

.cb {
    margin-left: 20px;
}

input::placeholder{
    color: whitesmoke;
    font-family: audio;
    font-size: small;
}

input:disabled::placeholder{
    color: grey;
}

h1, h2, h3, h4, h5, h6, label{
    color: white;
    font-family: audio;
}

.Submit-btn{
    color: white;
    background: rgba(0, 128, 19, 0.25);
    backdrop-filter: blur( 1px );
    transition-duration: .2s;
}

.Submit-btn:hover{
    background: rgba(0, 184, 28, 0.25);
    backdrop-filter: blur( 5px );
}



.ccs{
    width: min(100px, 10%);
}

.title{
    font-size: 6vw;
    letter-spacing: 20px;
    font-family: nusar;
}

.tag{
    font-size: min(2vw, 12px);
}

.tag, .title{
    text-shadow: 0 0 10px black;
}
.error{
    color: red;
    font-size: 12px;
    margin-top: 10px;
    margin-bottom: 5px;
}



.hiddenBox{
    display: flex;
    flex-direction: column;
    text-align  : center;
}   

.btn-txt-code{
    background: none;
    text-decoration: underline;
    border: none;
    color: white;
    margin-bottom: 2vh;
}