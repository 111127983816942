@import url('https://fonts.googleapis.com/css2?family=Source+Code+Pro&display=swap');
.wrapper{
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
}
.page {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    width: 60%;
}

.titlebar {
    background-color: #3e3e3e;
    height: 44px;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    padding: 16px;
    box-sizing: border-box;
}

.dot {
    width: 12px;
    height: 12px;
    background-color: #ccc;
    display: inline-block;
    border-radius: 50%;
}

.dot.close {
    background-color: #ef6866;
}

.dot.minimise {
    background-color: #f5ed49;
}

.dot.maximise {
    background-color: #89c665;
}

.dot + .dot {
    margin-left: 7px;
}


.terminal-container {
    border: 2px solid #7f7f7f;
    background-color: #000000;
    font-family: 'Source Code Pro', monospace;
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
    height: 85vh;
}
.contentT{
    font-size: 18px;
    align-items: flex-start;
    text-align: left;
    padding: 20px;
    width: 100%;
    height:700px;
    overflow-y: auto;
}

.contentT::-webkit-scrollbar {
    width: 8px;
  }
  
  .contentT::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  .contentT::-webkit-scrollbar-thumb {
    background-color: #d3d3d3;
    border-radius: 4px;
  }
  
  .contentT::-webkit-scrollbar-thumb:hover {
    background-color: #a9a9a9;
  }
  




span {
    color: rgb(0, 255, 0);
}

.blinker {
    opacity: 1;
    margin-bottom: -2px;
    height: 15px;
    margin-left: -5px;
    border-left: 7px solid rgb(0, 255, 0);
    animation: blinker 0.9s steps(2, start) infinite;
}

@keyframes blinker {
    to { 
        visibility: hidden; 
    }
}



.shortcuts{
    display: flex;
    justify-content: space-evenly;
    align-items: center;
}
.shortcuts>h1{
    color: rgb(0, 255, 0);
    font-family: 'Source Code Pro', monospace;
    font-size: 23px;
}